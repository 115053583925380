<template>
  <v-col
    align="center"
    style="background-color:#F0F2F5; border-radius:10px"
  >
    <v-card
      :loading="loading"
      class="mx-auto my-12"
      max-width="374"
    >
      <template slot="progress">
        <v-progress-linear
          color="black"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <!-- Start header content-->
      <v-row
        class="pt-6 px-6"
        align="center"
      >
        <v-avatar
          size="35"
        >
          <v-icon>
            {{ icons.mdiAccountCircle }}
          </v-icon>
        </v-avatar>
        <v-col align="left">
          <v-row>
            <p
              class="ml-3 font-weight-bold"
              style="color:black"
            >
              {{ user.name }}
            </p>
          </v-row>
        </v-col>
        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
      </v-row>
      <!-- End header content-->

        
      <v-img
        height="250"
        class="mt-10"
        :src="getImages.length ? getImages[0].preview : ''"
      ></v-img>

      <v-divider class="mx-4"></v-divider>
      <p
        style="color:black; text-align:left; margin-left:12px"
      >
        {{ post.post_name }}
      </p>
      <p
        style="color:black; text-align:left; margin-left:12px"
      >
        {{ post.description }}
      </p>

      <!-- Start footer content-->
      <v-row
        class="mt-2 mx-3"
        justify="space-between"
        align-content="center"
      >
        <p
          style="color:#1190dd; font-size:14px"
          class="mt-2"
        >
          Lihat Insight
        </p>
        <v-btn
          class="text-uppercase white--text"
          dense
          small
          color="#1190dd"
        >
          Promosikan Postingan
        </v-btn>
      </v-row>
      <hr
        style="color:#f3f3f3"
        class="mx-4"
      >
      <v-card-actions>
        <v-col
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Start Button  -->
          <v-row
            justify="space-between"
            align="center"
            class="mx-2"
          >
            <v-row no-gutters>
              <p>
                <v-icon class="mr-5">
                  {{ icons.mdiCardsHeartOutline }}
                </v-icon>
              </p>
              <p>
                <v-icon class="mr-5">
                  {{ icons.mdiCommentOutline }}
                </v-icon>
              </p>
              <p>
                <v-icon class="mr-5">
                  {{ icons.mdiSend }}
                </v-icon>
              </p>
            </v-row>
            <p><v-icon>{{ icons.mdiBookmarkOutline }}</v-icon></p>
          </v-row>
          <!-- End Button  -->

          <!-- Start like -->
          <v-row align-content="center">
            <img 
              src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
              style="border-radius:100%; width:20px; height:20px; margin-left:-1px;"
            >
            <img 
              src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
              style="border-radius:100%; width:20px; height:20px; margin-left:-1px;"
            >
            <img 
              src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
              style="border-radius:100%; width:20px; height:20px; margin-left:-1px;"
            >
            <p
              class="ml-2 black--text"
              style="font-size:13px"
            >
              Disukai oleh
            </p>
            <p
              class="ml-1 black--text font-weight-bold"
              style="font-size:13px"
            >
              mpi.novi
            </p> 
            <p
              class="ml-1 black--text"
              style="font-size:13px"
            >
              dan
            </p> 
            <p
              class="ml-1 black--text font-weight-bold"
              style="font-size:13px"
            >
              25 lainnya
            </p>
          </v-row>
          <!-- End like -->
        </v-col>
      </v-card-actions>
      <!-- End footer content-->
    </v-card>
  </v-col>
</template>

<script>
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
import useAppConfig from '@core/@app-config/useAppConfig'
import {
  mdiChevronLeft,
  mdiClose,
  mdiBrightness1,
  mdiSend,
  mdiCommentOutline,
  mdiCardsHeartOutline,
  mdiDotsVertical,
  mdiBookmarkOutline,
  mdiAccountCircle,
} from '@mdi/js'

export default {
  name: 'PreviewPostInstagram',
  props: { imageRef: { default: () => [] }, post: { type: Object } },
  setup() {
    const { isDark } = useAppConfig()

    return {
      icons: {
        mdiChevronLeft,
        mdiClose,
        mdiBrightness1,
        mdiSend,
        mdiCommentOutline,
        mdiCardsHeartOutline,
        mdiDotsVertical,
        mdiBookmarkOutline,
        mdiAccountCircle,
      },
      isDark,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    getImages() {
      return this.imageRef.uppy?.getFiles()
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
}
</script>

<style>
</style>