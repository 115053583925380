<template>
  <div>Don't have preview post</div>
</template>

<script>
export default {
  name: 'NoPreviewPost',
}
</script>

<style>
</style>