<template>
  <div class="container">
    <div class="tiktok">
      <div class="header">
        <h4>
          <span class="active">Following</span> | <span>For You</span>
        </h4>
      </div>

      <div class="image-container">
        <v-img
          width="100%"
          height="100%"
          lazy-src="''"
          :src="getImages.length ? getImages[0].preview : ''"
        ></v-img>
      </div>

      <div class="sidebar">
        <div class="avatar">
          <v-avatar
            size="40"
          >
            <v-icon color="white">
              {{ icons.mdiAccountCircle }}
            </v-icon>
          </v-avatar>
          <v-badge
            color="error"
            :icon="icons.mdiPlus"
            offset-x="30"
            offset-y="25"
          >
          </v-badge>
        </div>
        <div class="sidebar-item">
          <v-icon
            color="white"
            size="30"
          >
            {{ icons.mdiHeart }}
          </v-icon>
          <span class="sidebar-item-text">7887</span>
        </div>
        <div class="sidebar-item">
          <v-icon
            color="white"
            size="30"
          >
            {{ icons.mdiChatProcessing }}
          </v-icon>
          <span class="sidebar-item-text">71</span>
        </div>
        <div class="sidebar-item">
          <v-icon
            color="white"
            size="30"
          >
            {{ icons.mdiBookmark }}
          </v-icon>
          <span class="sidebar-item-text">1434</span>
        </div>
        <div class="sidebar-item">
          <v-icon
            color="white"
            size="30"
          >
            {{ icons.mdiShare }}
          </v-icon>
          <span class="sidebar-item-text">102</span>
        </div>
        <v-avatar
          size="45"
          class="mt-3"
        >
          <v-icon color="primary">
            {{ icons.mdiAlbum }}
          </v-icon>
        </v-avatar>
      </div>

      <div>
        <div class="detail-container">
          <h4 class="username">
            {{ `@${user.name ? user.name : username }` }}
          </h4>
          <p class="description">
            {{ post.post_name || 'Title' }}. {{ post.description || 'Description' }}. <span class="hashtag">#hashtag #hashtag #hashtag</span>
          </p>
          <span class="sound">
            <v-icon
              small
              color="#808080"
            >
              {{ icons.mdiMusic }}
            </v-icon>
            Original sound - @creator
          </span>
        </div>
        
        <div class="navigation">
          <div class="nav-item">
            <v-icon color="white">
              {{ icons.mdiHomeVariant }}
            </v-icon>
            <span class="nav-item-text nav-active">Home</span>
          </div>
          <div class="nav-item">
            <v-icon color="#808080">
              {{ icons.mdiBasketOutline }}
            </v-icon>
            <span class="nav-item-text">Shop</span>
          </div>
          <div class="nav-item">
            <div class="button">
              <v-icon color="black">
                {{ icons.mdiPlus }}
              </v-icon>
            </div>
          </div>
          <div class="nav-item">
            <v-icon color="#808080">
              {{ icons.mdiMessageMinusOutline }}
            </v-icon>
            <span class="nav-item-text">Inbox</span>
          </div>
          <div class="nav-item">
            <v-icon color="#808080">
              {{ icons.mdiAccountOutline }}
            </v-icon>
            <span class="nav-item-text">Profile</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
import useAppConfig from '@core/@app-config/useAppConfig'
import {
  mdiChatProcessing,
  mdiHeart,
  mdiShare,
  mdiHomeVariant,
  mdiBookmark,
  mdiBasketOutline,
  mdiMessageMinusOutline,
  mdiAccountOutline,
  mdiMusic,
  mdiPlus,
  mdiAccountCircle,
  mdiAlbum,
} from '@mdi/js'

export default {
  name: 'PreviewPostTiktok',
  props: { imageRef: { default: () => [] }, post: { type: Object } },
  setup() {
    const { isDark } = useAppConfig()

    return {
      icons: {
        mdiChatProcessing,
        mdiHeart,
        mdiShare,
        mdiHomeVariant,
        mdiBookmark,
        mdiBasketOutline,
        mdiMessageMinusOutline,
        mdiAccountOutline,
        mdiMusic,
        mdiPlus,
        mdiAccountCircle,
        mdiAlbum,
      },
      isDark,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    getImages() {
      return this.imageRef.uppy?.getFiles()
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  border-radius: 10px;
  background-color: #f0f2f5;
}

.tiktok {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1.5rem 0;
  border-radius: 16px;
  height: 552px;
  width: 312px;
  background: black;
  user-select: none;
}

.header {
  display: flex;
  justify-content: center;
  color: #808080;
}

.active {
  color: lightgrey;
  font-weight: bold;
}

.image-container {
  position: absolute;
  top: 20%;
  width: 100%;
  height: 45%;
  background-image: gray;
}

.detail-container {
  position: relative;
  z-index: 1;
  margin-bottom: 0.6rem;
  padding: 0 1rem;
  color: white;
  font-size: 12px;
  background: transparent;
}

.username {
  font-size: 1.15em;
  font-weight: 500 !important;
  margin: 0 0 0.5rem 0;
}

.description {
  width: 80%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0.5rem;
  font-weight: 300;
}

.hashtag {
  font-weight: 500;
}

.sound {
  font-size: 0.9em;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4px 0 0 0;
  border-top: 1.2px solid grey;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  .nav-active {
    color: white;
  }
}

.nav-item-text {
  font-size: 10px;
  color: #808080;
}

.button {
  position: relative;
  border-radius: 8px;
  padding: 3px 10px;
  background: white;
}

.sidebar {
  position: absolute;
  top: 25%;
  right: 0;
  z-index: 100;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding-right: 1rem;
}

.avatar {
  position: relative;
  margin-bottom: 1.2rem;
  border: 1px solid white;
  border-radius: 999px;
}

.follow-btn {
  position: absolute;
  padding: 1px;
  border-radius: 999px;
  background: palevioletred;
}

.sidebar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
}

.sidebar-item-text {
  font-size: 0.7rem;
  color: white;
}
</style>