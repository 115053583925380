<template>
  <div>
    <v-row
      justify="space-between"
    >
      <!-- Start content left -->
      <v-col
        justify="start"
        align="left"
        cols="12"
        md="6"
      >
        <!-- Start button back & title head -->
        <h2
          class="font-weight-bold mb-4"
          :class="isDark ? '' : 'black--text'"
        >
          <v-icon
            class="back-button"
            @click="$router.go(-1)"
          >
            {{ icons.mdiChevronLeft }}
          </v-icon>
          {{ pageTitle }}
        </h2> 
        <!-- End button back & title head -->
      
        <!-- Start content -->
        <v-col>
          <!-- Start Rejection Note -->
          <div v-if="post.statusApproval === 'Rejected'">
            <v-alert
              v-for="(data, index) in post.approvement"
              :key="index + data.statusApproval"
              outlined
              type="error"
              text
              style="font-size: 14px;"
            >
              <span><strong>Rejected By: </strong>{{ data.user.name }}</span>
              <br>
              <span><strong>Note: </strong>{{ data.notesRejected }}</span>
            </v-alert>
          </div>
          <!-- End Rejection Note -->

          <v-form
            ref="form"
            @submit.prevent="createPost"
          >
            <v-text-field
              v-model="post.post_name"
              class="mt-6 mb-2 text-caption"
              label="Post name"
              dense
              outlined
              :rules="[v => !!v || 'Name is required']"
            >
            </v-text-field>
            <!-- Start description -->
            <v-textarea
              id="messageText"
              v-model="post.description"
              label="Description"
              auto-grow
              outlined
              rows="4"
              row-height="30"
              :rules="[v => !!v || 'Description is required']"
              class="mb-2"
            ></v-textarea>
            <!-- End deskripsi -->
          
            <!-- Start upload file -->
            <uppyUploader
              ref="uppy-data"
              :data-index="dataIndex"
              :status="status_message_template"
              :data-uppy="propsUppyUploader"
              :config="uppySettings"
              :dashboard-config="uppyDashboardSettings"
              class="uppy-container"
              :style="validateImageStyles"
            />
            <span
              v-if="noImageUploaded"
              style="font-size: 12px; line-height: 12px; color: red; padding: 0 12px;"
            >Image is required</span>
            <!-- End upload file -->
          </v-form>
        </v-col>
        <!-- End content -->
      </v-col>
      <!-- End content left -->

      <!-- Start preview post area right -->
      <v-col
        cols="12"
        md="6"
      >
        <!-- Start tab -->
        <v-row
          justify="space-between"
          align-content="center"
          align="center"
        >
          <v-col
            cols="12"
            sm="auto"
          >
            <span
              class="text-subtitle-1"
              style="text-align:left"
            >
              Preview post
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="auto"
          >
            <v-tabs>
              <v-tab
                class="text-capitalize"
                @click="previewPost = 'WhatsApp'"
              >
                WhatsApp
              </v-tab>
              <v-tab
                class="text-capitalize"
                @click="previewPost = 'Facebook'"
              >
                Facebook
              </v-tab>
              <v-tab
                class="text-capitalize"
                @click="previewPost = 'Instagram'"
              >
                Instagram
              </v-tab>
              <v-tab
                class="text-capitalize"
                @click="previewPost = 'Tiktok'"
              >
                Tiktok
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <!-- End tab -->

        <!-- Start Preview Post -->
        <PreviewPostWhatsapp
          v-if="previewPost === 'WhatsApp'"
          :image-ref="$refs['uppy-data']"
          :post="post"
          :form-add-template="formAddTemplate"
        />
        <PreviewPostFacebook
          v-if="previewPost === 'Facebook'"
          :image-ref="$refs['uppy-data']"
          :post="post"
        />
        <PreviewPostInstagram
          v-if="previewPost === 'Instagram'"
          :image-ref="$refs['uppy-data']"
          :post="post"
        />
        <PreviewPostTiktok
          v-if="previewPost === 'Tiktok'"
          :image-ref="$refs['uppy-data']"
          :post="post"
        />
        <!-- End Preview Post -->

        <!-- Start Settings -->
        <!-- WA Settings -->
        <div
          v-if="previewPost === 'WhatsApp'"
          class="mb-10"
        >
          <div class="text-subtitle-1 mt-8 mb-4">
            Settings
          </div>

          <v-select
            v-model="broadcastMode"
            :items="broadcastModeOptions"
            label="Broadcast Mode"
            class="py-0 my-0"
            outlined
            dense
            return-object
          >
            <template v-slot:append-outer>
              <v-tooltip
                :open-on-click="true"
                :open-on-hover="false"
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="on.click"
                  >
                    {{ icons.mdiInformation }}
                  </v-icon>
                </template>
                <span>Aggresive : {{ $t('broadcasts.broadcastModeAggresive') }}</span>
                <br />
                <span>Safe : {{ $t('broadcasts.broadcastModeSafe') }}</span>
              </v-tooltip>
            </template>
          </v-select>
              
              
          <!-- <div
            v-for="(button, index) of formAddTemplate.listButton"
            :key="index"
            class="mb-2"
          >
            <v-btn
              class="mt-1"
              color="primary"
            >
              {{ button.label }}
            </v-btn>
            <v-btn
              icon
              small
              @click="removeButton(index)"
            >
              <v-icon size="20">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>
          <v-expansion-panels
            class="mb-2"
            accordion
          >
            <v-expansion-panel>
              <v-expansion-panel-header> Emoji 😃 </v-expansion-panel-header>
              <v-expansion-panel-content class="align-center align-content-center px-0">
                <emoji-picker
                  style="width: 100%"
                  :class="isDark ? 'dark' : 'light'"
                  @emoji-click="addEmoji($event)"
                ></emoji-picker>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Ekstra Field </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-chip
                  small
                  block
                  color="primary"
                  class="text-capitalize mr-1"
                  @click="addText(`{{phone_number}}`)"
                >
                  phone number
                </v-chip>
                <v-chip
                  small
                  block
                  color="error"
                  class="text-capitalize mr-1"
                  @click="addText(`{{name}}`)"
                >
                  Name
                </v-chip>
                <v-chip
                  v-for="item in mustacheList"
                  :key="item.value"
                  block
                  small
                  color="primary"
                  class="text-capitalize mr-1"
                  @click="addText(`{{${item.field}}}`)"
                >
                  {{ item.name }}
                </v-chip>
                <v-select
                  v-model="extraFieldChoosed"
                  :items="dataExtraFieldProps.contacts_list"
                  label="Extra Field"
                  dense
                  hide-details
                  item-text="list_name"
                  class="mt-4 mb-3"
                  item-value="id"
                  @change="addExtrafield"
                ></v-select>
                <v-chip
                  v-for="item in extraFieldData"
                  :key="item.value"
                  block
                  small
                  dense
                  color="primary"
                  class="text-capitalize mr-1"
                  @click="addText(`{{${item.name}}}`)"
                >
                  {{ item.name }}
                </v-chip>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Button Header </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  v-model="formAddTemplate.buttonFooter"
                  required
                  label="Button Header"
                  outlined
                  dense
                  hide-details
                  class="mb-3"
                ></v-text-field>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Link Button </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  v-model="labelLink"
                  required
                  label="Label"
                  outlined
                  dense
                  hide-details
                  class="mb-3"
                ></v-text-field>
                <v-text-field
                  v-model="valueLink"
                  required
                  label="Link URL"
                  outlined
                  dense
                  hide-details
                  class="mb-3"
                ></v-text-field>
                <v-btn
                  color="primary"
                  @click="addButtonLink"
                >
                  {{ $t('add') }}
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Call Button </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  v-model="labelCall"
                  required
                  label="Label"
                  outlined
                  dense
                  hide-details
                  class="mb-3"
                ></v-text-field>
                <v-text-field
                  v-model="valueCall"
                  required
                  label="Phone Number"
                  outlined
                  type="number"
                  dense
                  hide-details
                  class="mb-3"
                ></v-text-field>
                <v-btn
                  color="primary"
                  @click="addButtonCall"
                >
                  {{ $t('add') }}
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Reply Button </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  v-model="labelReply"
                  required
                  label="Reply Text"
                  outlined
                  dense
                  hide-details
                  class="mb-3"
                ></v-text-field>
                <v-btn
                  color="primary"
                  @click="addButtonReply"
                >
                  {{ $t('add') }}
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels> -->
        </div>
        <!-- End Settings -->

        <!-- Spacer -->
        <div class="pa-2" />

        <!-- Start button bottom -->
        <v-row
          cols="12"
          justify="space-between"
          align="center"
        >
          <v-col>
            <v-btn
              outlined
              block
              class="mr-4"
              @click="$route.name === 'create-post' ? saveAsDraft(true) : $router.go(-1)"
            >
              {{ $route.name === 'create-post' ? 'Save as Draft' : 'Cancel' }}
            </v-btn>
          </v-col>
          <v-col v-if="$route.name === 'edit-post'">
            <v-btn
              outlined
              block
              color="error"
              class="mr-4"
              @click="openDialogDeletePost($route.params.postId)"
            >
              Delete Post
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="primary"
              outlined
              block
              style="color:white"
              type="submit"
              @click.prevent="createPost"
            >
              {{ $route.name === 'create-post' ? 'Create Post' : 'Save Post' }}
            </v-btn>
          </v-col>
        </v-row>
        <!-- End button bottom -->
      </v-col>
      <!-- End preview post area right -->

      
      <!-- dialog delete post -->
      <v-dialog
        v-model="dialogDeletePost"
        width="600px"
      >
        <v-card>
          <v-card-title>
            <h3 class="font-weight-bold">
              {{ $t('warning') }}
            </h3>
          </v-card-title>
          <v-card-text>
            {{ $t('deleteMessage') }}
          </v-card-text>
          <v-divider class="bottom-action">
          </v-divider>
          <v-card-actions class="justify-end text-end bottom-action">
            <v-btn
              color="#ffeeee"
              class="mr-3"
              @click="dialogDeletePost = false"
            >
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn
              color="success"
              @click="deletePost(postId)"
            >
              {{ $t('ok') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Display for size sm -->
  </div>
</template>

<script>
/* eslint-disable */
import messageTemplate from '@/components/dialog/broadcast/message_template.vue'
import uppyUploader from '@/components/UppyUploader.vue'
import useAppConfig from '@core/@app-config/useAppConfig'
import {
  mdiBrightness1,
  mdiChevronLeft,
  mdiClose,
  mdiCommentOutline,
  mdiShare,
  mdiThumbUpOutline,
  mdiInformation,
} from '@mdi/js'
import 'emoji-picker-element'
import insertTextAtCursor from 'insert-text-at-cursor'
import NoPreviewPost from './NoPreviewPost.vue'
import PreviewPostFacebook from './PreviewPostFacebook.vue'
import PreviewPostInstagram from './PreviewPostInstagram.vue'
import PreviewPostWhatsapp from './PreviewPostWhatsapp.vue'
import PreviewPostTiktok from './PreviewPostTiktok.vue'

export default {
  name: 'CreatePost',
  components: {
    uppyUploader,
    PreviewPostFacebook,
    PreviewPostInstagram,
    PreviewPostWhatsapp,
    messageTemplate,
    NoPreviewPost,
    PreviewPostTiktok,
  },
  setup() {
    const { isDark } = useAppConfig()

    return {
      icons: {
        mdiChevronLeft,
        mdiClose,
        mdiBrightness1,
        mdiThumbUpOutline,
        mdiCommentOutline,
        mdiShare,
        mdiInformation,
      },
      isDark,
    }
  },
  props: {
    selectedListContacts: { type: Array, default: () => [] },
  },
  data: () => {
    return {
      labelLink: '',
      valueLink: '',
      labelCall: '',
      valueCall: '',
      labelReply: '',
      formAddTemplate: {
        template_name: 'Custom Template',
        category: {},
        language: '',
        message: '',
        quick_reply: false,
        link_attachment: '',
        last_update: '',
        attachments: [],
        on_reply: false,
        on_reply_message: {
          message: '',
          attachments: [],
        },
        listButton: [],
        buttonFooter: '',
        loading: false,
        selection: 1,
      },
      mustacheList: [],
      listTemplate: [],
      templateName: [],
      extraField: [],
      dataIndex: `${Math.round(Math.random() * 100000)}`,
      status_message_template: 'add',
      propsUppyUploader: null,
      dataExtraFieldProps: '',
      uppySettings: {
        restrictions: {
          maxFileSize: 100 * 1024 * 1024,
          allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'],
        },
        autoProceed: false,
      },
      uppyDashboardSettings: {
        note: ' Image Only',
        theme: 'light',
        height: 0,
        hideUploadButton: true,
      },
      previewPost: 'WhatsApp',
      messageTemplate: 0,
      messageTemplateList: [],
      localExtraFieldContainer: [],
      extraFieldAlert: false,
      extraFieldChoosed: 'null',
      extraFieldData: [],
      dialogDeletePost: false,
      postId: null,
      noImageUploaded: false,
      broadcastMode: { text: 'Safe', value: 'safe' },
      broadcastModeOptions: [
        { text: 'Aggressive', value: 'aggressive' },
        { text: 'Safe', value: 'safe' },
      ],
    }
  },
  computed: {
    contactList() {
      let data = []

      if (this.selectedListContacts.length > 0) {
        data = [{ text: 'Global', value: 'global' }, ...this.selectedListContacts]
      }

      return data
    },
    pageTitle() {
      if (this.$route.name === 'edit-post') {
        return 'Edit Post'
      }

      return 'Create Post'
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    schedule() {
      return this.$store.getters['marketingCalendar/getSchedule']
    },
    post() {
      return this.$store.getters['marketingCalendar/getPostTemplate']
    },
    campaignId() {
      return this.$route.params.campaignId
    },
    getImages() {
      if (this.$refs['uppy-data']) {
        return this.$refs['uppy-data'].uppy?.getFiles()
      }

      return null
    },
    locale() {
      return this.$i18n.locale
    },
    minTimePicker() {
      let dateNow = new Date()
      let dateChoose = this.schedule.date !== '' ? new Date(this.schedule.date) : dateNow
      if (dateNow.getTime() === dateChoose.getTime()) return `${new Date().getHours()}:${new Date().getMinutes()}`
      if (
        dateNow.getDate() === dateChoose.getDate() &&
        dateNow.getMonth() === dateChoose.getMonth() &&
        dateNow.getFullYear() === dateChoose.getFullYear()
      ) {
        return `${new Date().getHours()}:${new Date().getMinutes()}`
      }

      return null
    },
    dateText() {
      return this.schedule.date
    },
    timeText() {
      return this.schedule.time
    },
    validateImageStyles() {
      return this.noImageUploaded ? 'border: 2px solid red' : ''
    },
    choosenCampaign() {
      return this.$store.getters['campaign_manager/getSpecifiedCampaignsData']
    },
  },
  watch: {
    contactList() {
      const extra = this.$store.getters['broadcast/getBroadcastExtraField'].global
      this.extraFieldChoosed = 'global'
      this.extraField = extra
      this.checkExtraFieldAv()
      this.checkExtraFieldAvOnreply()
    },
  },
  async created() {
    if (this.$route.name === 'create-post') {
      this.$store.commit('marketingCalendar/setPostTemplate', {
        post_name: '',
        description: '',
        message_template: '',
        idCampaign: this.campaignId,
        broadcastMode: 'safe',
      })
    } else if (this.$route.name === 'edit-post') {
      this.broadcastMode = {
        text: this.post.broadcastMode === 'safe' ? 'Safe' : 'Aggressive',
        value: this.post.broadcastMode,
      }
    }
  },
  beforeMount() {
    // Populate image for edit file
    if (this.$route.name === 'edit-post') {
      const attachments = this.post.message_template?.attachments.map(a => ({
        type: a.type,
        uploadURL: a.url,
      }))
      this.propsUppyUploader = { attachments }
      this.uppyDashboardSettings.disabled = false
    }
  },
  async mounted() {
    const messageTemplatePg = await this.$store.dispatch('global/getSettingsByKey', {
      sub_id: this.user.sub_id,
      token: this.user.token,
      key: 'message_template',
    })
    if (messageTemplatePg.status) {
      this.listTemplates = messageTemplatePg.data.value
    }
    this.messageTemplateList = this.listTemplates
      .filter(elm => {
        elm.index = IDBIndex

        return elm.contact_list.value === 'whatsapp'
      })
      .map(el => {
        const templateName = el.template_name

        return { ...el, templateName }
      })
    const dataContactsFromApi = await this.$store.dispatch('broadcast/getContacts', {
      subId: this.user.sub_id,
      token: this.user.token,
    })
    this.dataExtraFieldProps = dataContactsFromApi.data
    const { data } = await this.$store.dispatch('campaign_manager/fetchSpecifiedCampaign', {
      ID_for_fetching: this.campaignId,
    })
    //console.log(data, 'choosenCampaign')
  },
  methods: {
    addButtonLink() {
      if (this.labelLink && this.valueLink) {
        if (!this.formAddTemplate.listButton) this.formAddTemplate.listButton = []
        this.formAddTemplate.listButton.push({
          label: this.labelLink,
          urlButton: {
            displayText: this.labelLink,
            url: `https://${this.valueLink}`,
          },
        })
        this.labelLink = ''
        this.valueLink = ''
      }
      this.resetIndex()
    },
    addButtonCall() {
      if (this.labelCall && this.valueCall) {
        if (!this.formAddTemplate.listButton) this.formAddTemplate.listButton = []
        this.formAddTemplate.listButton.push({
          label: this.labelCall,
          callButton: {
            displayText: this.labelCall,
            phoneNumber: this.valueCall,
          },
        })
        this.labelCall = ''
        this.valueCall = ''
      }
      this.resetIndex()
    },
    addButtonReply() {
      if (this.labelReply) {
        this.formAddTemplate.listButton.push({
          label: this.labelReply,
          quickReplyButton: {
            displayText: this.labelReply,
            id: 'id-like-buttons-message2',
          },
        })
        this.labelReply = ''
        this.valueReply = ''
      }
      this.resetIndex()
    },
    createPost() {
      if (!this.post.post_name || !this.post.description) {
        this.$refs.form.validate()
        if (!this.getImages.length) {
          this.noImageUploaded = true
        }
        return
      }
      this.formAddTemplate.on_reply_message = this.on_reply_message
      this.formAddTemplate.operator = this.user.email
      this.formAddTemplate.extraField = this.extrafieldName
      const attachments = this.getImages.map(image => ({ ...image, url: image.uploadURL }))
      this.formAddTemplate.attachments = attachments
      this.post.message_template = this.formAddTemplate
      this.post.broadcastMode = this.broadcastMode.value
      this.post.post_creator = {
        name: this.user.name,
        role: this.user.role,
        email: this.user.email,
        sub_id: this.user.sub_id,
      }

      if (this.$route.name === 'edit-post') {
        this.$store.dispatch('marketingCalendar/updatePostTemplate', {
          sub_id: this.user.sub_id,
          data: this.post,
          token: this.user.token,
        })
      } else {
        //console.log(this.choosenCampaign.approvers.length, 'this.choosenCampaign.length')
        if (this.choosenCampaign.approvers.length == 1) {
          //console.log(this.choosenCampaign.approvers[0], 'this.choosenCampaign.approvers[0]')
          if (this.choosenCampaign.approvers[0] === this.user.role && this.post.post_creator.name === this.user.name) {
            this.post.approvement = []
            this.post.approvement.push({
              user: this.user,
              statusApproval: 'Approved',
            })
            this.post.statusApproval = 'Approved'
          }
        }
        this.$store.dispatch('marketingCalendar/createPost', {
          sub_id: this.user.sub_id,
          data: this.post,
          token: this.user.token,
        })
      }
      this.$router.push({
        path: `/campaign-manager/${this.campaignId}/marketing-calendar`,
      })
    },
    checkExtraFieldAv() {
      if (this.selectedListContacts.length > 1) {
        const regexp = /{{\s*[\w\.]+\s*}}/g
        const match = this.listTemplates[this.messageTemplate].message.match(regexp)
        let countLocalExtraField = 0
        if (match) {
          match.forEach(el => {
            if (this.localExtraFieldContainer.includes(el)) countLocalExtraField += 1
          })
        }
        this.extraFieldAlert = countLocalExtraField !== 0
      } else {
        this.extraFieldAlert = false
      }
    },
    saveAsDraft(isDraft) {
      if (!this.post.post_name) {
        this.$refs.form.validate()
        if (!this.getImages.length) {
          this.noImageUploaded = true
        }
        return
      }
      this.formAddTemplate.on_reply_message = this.on_reply_message
      this.formAddTemplate.operator = this.user.email
      this.formAddTemplate.extraField = this.extrafieldName
      const attachments = this.getImages ? this.getImages.map(image => ({ ...image, url: image.uploadURL })) : []
      this.formAddTemplate.attachments = attachments
      this.post.message_template = this.formAddTemplate
      this.$store.dispatch('marketingCalendar/createPost', {
        sub_id: this.user.sub_id,
        data: {
          ...this.post,
          isDraft,
        },
        token: this.user.token,
      })
      this.$router.push({
        path: `/campaign-manager/${this.campaignId}/marketing-calendar`,
        query: { page: 'getByIdCampaign' },
      })
    },
    addEmoji(event) {
      insertTextAtCursor(document.querySelector('#messageText'), event.detail.unicode)
    },
    addText(text) {
      insertTextAtCursor(document.querySelector('#messageText'), text)
    },
    removeButton(index) {
      this.formAddTemplate.listButton.splice(index, 1)
      this.resetIndex()
    },
    resetIndex() {
      this.formAddTemplate.listButton.forEach((btn, index) => {
        this.formAddTemplate.listButton[index].index = index + 1
      })
    },
    addExtrafield() {
      this.extraFieldData = this.dataExtraFieldProps.extra_field[this.extraFieldChoosed]
    },
    openDialogDeletePost(postId) {
      this.dialogDeletePost = true
      this.postId = postId
    },
    async deletePost(postId) {
      await this.$store.dispatch('marketingCalendar/deletePost', {
        sub_id: this.user.sub_id,
        postId,
        token: this.user.token,
      })
      this.$router.push({
        path: `/campaign-manager/${this.campaignId}/marketing-calendar`,
      })
    },
    showPreviewTab(channelType) {
      // TODO this is not working after pull from repo, something has changed
      const temp = this.$store.getters['campaign_manager/getAllCampaignsData']
        .filter(campaign => campaign.id === this.campaignId)
        .map(campaign => campaign.channelType)
        .map(channel => channel.map(c => c.type))

      const allChannelTypes = []
      temp.forEach(channel => allChannelTypes.push(...channel))

      return allChannelTypes.some(type => type === channelType)
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 400;
  color: rgb(43, 42, 42);
}

.selected-channel-btn {
  background: #e8f4f5;
}

.uppy-container {
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border 0.15s ease-in;
}
</style>